import React from 'react'
import Layout from '../components/Layout'
import RelatedProducts from '../components/RelatedProducts'
import Seo from '../components/Seo'
import { CUSTOM_HEADER_COMPONENTS, BASE_BREADCRUMBS_PRODUCTS } from '../constants'
import ProductImages from '../components/ProductImages'
import ProductOverview from '../components/ProductOverview'
import ProductDetails from '../components/ProductDetails'

const Product = ({ pageContext }) => {
  const { data, relatedProducts } = pageContext
  return (
    <Layout
      fullWidth
      banners={{ data: data.hierarchyNode.banners, name: data.hierarchyNode.name }}
      pageTitle={data.hierarchyNode.name}
      customPageHeaderComponent={CUSTOM_HEADER_COMPONENTS[data.hierarchyNode.custom_header_widget]}
      breadcrumbs={[...BASE_BREADCRUMBS_PRODUCTS,
      ...data.allParents.slice(1).map(parent => ({ name: parent.name, url: parent.url })), // Remove products from allParents array
      { name: data.hierarchyNode.name, url: data.hierarchyNode.url }
      ]}
    >
      <Seo title={data.hierarchyNode.name} description={data.hierarchyNode.meta_description} />
      {
        data.webpageId ? (
          <div>
            <div className="row mt-6">
              <div className="col-md-6">
                <ProductImages images={data.hierarchyNode.images} node={data.hierarchyNode} />
              </div>
              <div className="col-md-6">
                <ProductOverview product={data} />
              </div>
            </div>
            {
              (data.orderingInformation || data.resources) &&
              <div className="mt-5">
                <ProductDetails product={data} />
              </div>
            }
            {
              relatedProducts?.length > 0 &&
              <div className="mt-5 mb-5">
                <RelatedProducts node={data.hierarchyNode} relatedProducts={relatedProducts} />
              </div>
            }
          </div>
        )
          :
          <div className="m-5">
            <h2>Uh oh! webpageId "{data.hierarchyNode.webpage_id}" doesn't exist in webpages.json. </h2>
            <p>To fix this, please add "{data.hierarchyNode.webpage_id}" to webpages.json, along with all necessary page content.</p>
          </div>
      }
    </Layout>
  )
}

export default Product